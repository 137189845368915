import { ShoppingCart } from './shopping-cart'
import { MenuSheet } from './menu-sheet'
import { FiMenu } from 'react-icons/fi'
import { Icon } from '@iconify/react'
import { MypageIcons } from './mypage-icons'

type PropsType = {
  plans: {
    id: string
    days: number
    name: string
    price: number
  }[]
  isShowHB: boolean
  isShowCart: boolean
}

export const Menu = ({ plans, isShowHB, isShowCart }: PropsType) => {
  return (
    <div className='flex justify-between gap-x-3 items-center'>
      <div className='mr-5 hidden md:block'>
        <MypageIcons />
      </div>
      {isShowHB && (
        <div className='flex flex-row gap-3'>
          <MenuSheet>
            <FiMenu className='size-7' />
          </MenuSheet>
        </div>
      )}
      {isShowCart && (
        <ShoppingCart plans={plans} dependsOn={false}>
          <Icon
            icon='fluent:cart-20-regular'
            className='p-2 box-content size-7 bg-pink-600 rounded-full text-white'
          />
        </ShoppingCart>
      )}
    </div>
  )
}
