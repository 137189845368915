import { useMediaQuery } from '@uidotdev/usehooks'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { useRef, useState } from 'react'
import { BiSolidCommentDots } from 'react-icons/bi'
import { BsGlobe } from 'react-icons/bs'

export const langs = {
  ja: '日本語',
  en: 'English',
} as const

export const LocaleSetting = () => {
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const onOpen = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  const onOpenChange = () => {
    if (open) {
      onClose()
    } else {
      onOpen()
    }
  }

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogTrigger asChild>
          <button className='w-full rounded-full outline outline-2 outline-offset-2 outline-brand-blue flex flex-row items-center mx-auto justify-center'>
            <BsGlobe className='p-2 box-content size-5 text-brand-blue' />
            <div className='text-brand-blue text-base font-semibold'>
              言語設定 / Language
            </div>
          </button>
        </DialogTrigger>
        <DialogContent className='max-w-md p-0 pb-3 border-none'>
          <DialogHeader>
            <DialogTitle className='p-3 rounded-t'>言語設定</DialogTitle>
          </DialogHeader>
          <Content className='px-3' onClose={onClose} />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <button className='w-full rounded-full outline outline-2 outline-offset-2 outline-brand-blue flex flex-row items-center mx-auto justify-center'>
          <BsGlobe className='p-2 box-content size-5 text-brand-blue' />
          <div className='text-brand-blue text-base font-semibold'>
            言語設定 / Language
          </div>
        </button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>言語設定</DrawerTitle>
        </DrawerHeader>
        <Content className='py-5 px-4' onClose={onClose} />
      </DrawerContent>
    </Drawer>
  )
}

type ContentType = {
  className?: string
  onClose: () => void
}
const Content = ({ onClose, ...props }: ContentType) => {
  const ref = useRef<HTMLSelectElement>(null)
  const onSubmit = () => {}

  return (
    <>
      <div
        className={`flex flex-col gap-y-5 bg-white text-base leading-7 ${
          props.className ?? ''
        }`}
      >
        <div className='flex flex-col gap-y-5'>
          <div className='flex flex-col gap-y-1'>
            <div className='flex justify-between'>
              <div>
                <BiSolidCommentDots className='inline text-gray-700' />{' '}
                <span>言語</span>
              </div>
            </div>
            <select
              ref={ref}
              className='border border-gray-400 py-2 rounded'
              defaultValue='ja'
            >
              {Object.entries(langs).map(([k, v], i) => (
                <option value={k} key={i}>
                  {v}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex flex-col gap-y-3'>
          <button
            type='button'
            className='bg-brand-blue text-white w-full max-w-72 rounded-full py-2 mx-auto block whitespace-nowrap disabled:opacity-50'
            onClick={onSubmit}
          >
            保存
          </button>
          <button
            type='button'
            className='border border-black w-full max-w-72 rounded-full py-2 mx-auto block whitespace-nowrap'
            onClick={() => onClose()}
          >
            キャンセル
          </button>
        </div>
      </div>
    </>
  )
}
