import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { useState } from 'react'
import { LocaleSetting } from './locale-setting'
import { Icon } from '@iconify/react'

type PropsType = {
  children: React.ReactNode
}

export const MenuSheet = ({ children }: PropsType) => {
  const [open, setOpen] = useState(false)

  const menu = [
    { title: 'トップ', href: '/' },
    { title: 'eSIMとは？', href: '/' },
    { title: '利用方法', href: '/' },
    { title: 'よくある質問', href: '/' },
  ]

  const menu2 = [
    { title: '企業情報', href: '/' },
    { title: '利用規約', href: '/' },
    { title: '特定商取引法に基づく表記', href: '/' },
    { title: '個人情報の取り扱い', href: '/' },
    { title: 'お問合わせ', href: '/' },
  ]

  return (
    <Sheet open={open} onOpenChange={() => setOpen(!open)}>
      <SheetTrigger>{children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>&nbsp;</SheetTitle>
          <SheetDescription asChild>
            <div className='flex flex-col gap-y-5 items-start'>
              {menu.map((item, index) => (
                <div
                  key={index}
                  className='border-b-2 w-full text-left text-black'
                >
                  <a href={item.href}>
                    <div className='flex flex-row'>
                      <span className='text-base font-semibold'>
                        {item.title}
                      </span>
                      <Icon
                        icon='mingcute:right-line'
                        className='size-4 absolute right-7'
                      />
                    </div>
                  </a>
                </div>
              ))}
              <button className='w-full py-2 bg-brand-blue rounded-full'>
                <div className='text-white text-base font-semibold'>
                  ログイン
                </div>
              </button>
              {menu2.map((item, index) => (
                <div
                  key={index}
                  className='border-b-2 w-full text-left text-black'
                >
                  <a href={item.href}>
                    <div className='flex flex-row'>
                      <span className='text-xs'>{item.title}</span>
                      <Icon
                        icon='mingcute:right-line'
                        className='size-3 absolute right-7'
                      />
                    </div>
                  </a>
                </div>
              ))}
              <LocaleSetting />
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}
